<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('EXPENDITURE_TYPES_LABEL')})  | capitalize}}</strong>
    </div>
    <b-input-group v-else>
      <b-select
          :disabled="disabled"
          id="expenditure-type-input" type="text"
          :state="state"
          :value="content"
          @change="handleInput">
        <b-select-option :value="undefined">{{$t(undefinedMeans) | capitalize}}</b-select-option>
        <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : valueType === 'object' ? item : item._links.self.href">{{$t(item.name)}}</b-select-option>
      </b-select>
      <b-input-group-append v-if="allowAdd">
        <b-button variant="primary" @click="add()">
          <b-icon-plus-circle></b-icon-plus-circle>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-form-invalid-feedback id="expenditure-type-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('EXPENDITURE_TYPE_LABEL')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import ExpenditureType from "@/expenditureType/index";
import Project from "@/project";

export default {
  name: "ExpenditureTypeSingleSelector",
  props: ['state', 'errors', 'project', 'disabled', 'undefinedMeans', 'allowAdd', 'valueType'],
  mixins: [VModel, SendsMessages],
  watch: {
    project() {
      this.loadItems();
    }
  },
  methods: {
    add() {
      this.$router.push({name: 'EXPENDITURE_TYPE_REGISTER_TITLE'});
    },
    async loadItems() {
      try {
        this.loading = true;
        this.items = await ExpenditureType.findAll({perPage: 10000, filter: {status: true}});
        console.log('expenditure types')
        console.log(this.items);
        if(this.project) {
          const resp = await Project.findById(this.projectId);
          this.items = resp.data.budgetDetails.map(x => {
            x._embedded.expenditureType._links.self.href = x._embedded.expenditureType._links.self.href.replace('{?projection}', '');
            return x._embedded.expenditureType;
          });
        }
      } catch(e) {
        this.sendError('ERROR_LOADING_CATALOG', {}, e);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.loadItems();
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  computed: {
    projectId() {
      if(this.project) {
        const split = this.project.split('/');
        return split[split.length - 1];
      } else {
        return '';
      }
    }
  }
}
</script>

<style scoped>

</style>
