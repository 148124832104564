import http from "@/http";
import eventBus from "@/events";

const ExpenditureTypes = {
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'name') + sortDesc;

        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort};
        const params = {...filter, ...pagination};
        const resp = await http("/expenditureTypes/search/all", {params});
        eventBus.$emit('LIST_COUNT_EXPENDITURE_TYPE_EVENT', resp.data.page.totalElements);
        return resp.data['_embedded']['expenditureTypes'];
    },
    findById: async function (id) {
        return http.get('/expenditureTypes/' + id);
    },
    create: function (data) {
        return http.post('/expenditureTypes', data);
    },
    update: function (id, data) {
        return http.patch('/expenditureTypes/' + id, data);
    },
    nameExists(name) {
        return http.get("/expenditureTypes/exists/name", {params: {name}}).then(x => {
            return !x.data;
        });
    },
    emailExists(email) {
        return http.get("/expenditureTypes/exists/email", {params: {email}}).then(x => {
            return !x.data;
        });
    },
    deleteById(id) {
        return http.delete("/expenditureTypes/" + id);
    },
    enable(id) {
        return http.patch('/expenditureTypes/' + id + '/enable');
    },
    disable(id) {
        return http.patch('/expenditureTypes/' + id + '/disable');
    }
};

export default ExpenditureTypes;
