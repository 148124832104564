<template>
  <div>
    <div v-if="isBusy || loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('ORGANIZATIONS')}) | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="title || $t('ORGANIZATIONS') | capitalize">
      <b-card-text>
        <b-button class="mb-2" variant="info" @click="showModal">
          {{ $t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('ORGANIZATION')}) | capitalize }}
        </b-button>
        <b-list-group>
          <b-list-group-item variant="dark" :key="item.id" v-for="item of current">
            <div class="w-100">
              <div class="w-75 d-inline-block">
                {{ $t(item.name) }}
              </div>
              <div class="w-25 d-inline-block text-right">
                <b-button variant="danger" @click="removePermission(idx)">
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-modal id="organization-modal" size="xl" @ok="handleInput">
          <organization-list :only-active="true" :currently-selected="currentHrefs" @change="storeSelected"></organization-list>
        </b-modal>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {LoadData, SendsMessages, VModel} from "@/mixins";
import OrganizationList from "@/organization/OrganizationList";

export default {
  name: "OrganizationSelector",
  components: {OrganizationList},
  props: ['title', 'loading', 'value', 'initialValues'],
  mixins: [LoadData, VModel, SendsMessages],
  mounted() {
    this.currentHrefs = this.value;
    this.current = this.initialValues;
  },
  watch: {
    value(val) {
      this.currentHrefs = val;
    },
    initialValues(val) {
      this.current = val;
    }
  },
  methods: {
    removePermission(idx) {
      this.current.splice(idx, 1);
      this.currentHrefs.splice(idx);
      this.$emit('input', this.currentHrefs);
    },
    storeSelected(selected) {
      this.selected = selected;
      console.log('selected')
      console.log(this.selected);
      this.selectedHrefs = selected.map(x => x._links.self.href);
    },
    async showModal() {
      this.$bvModal.show('organization-modal');
    },
    handleInput() {
      this.selected.forEach(sel => {
        const idx = this.current.map(x => x._links.self.href).indexOf(sel._links.self.href);
        if(idx === -1) {
          this.current.push(sel)
        }
      });
      this.currentHrefs = this.current.map(x => x._links.self.href)
      this.$emit('input', this.currentHrefs);
      this.selected = [];
      this.selectedHrefs = [];
    }
  },
  data() {
    return {
      search: '',
      permissions: [],
      content: this.value,
      selected: [],
      selectedHrefs: [],
      current: [],
      currentHrefs: []
    }
  },
}
</script>

<style scoped>

</style>
