<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('PROJECT')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- title text input -->
          <b-form-group
              id="title-form-group"
              :label="$t('PROJECT_TITLE_LABEL') | capitalize"
              label-for="title-input"
              :state="state('title')">
            <b-form-input class="w-100 ml-1" id="title-input" type="text"
                          v-model="$v.form.title.$model"
                          :state="state('title')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="title-input-feedback">
              <div v-for="error in errors('title')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_TITLE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- title text input -->

          <div class="w-100">
            <!-- from input -->
            <b-form-group
                class="d-inline-block w-50"
                id="from-form-group"
                :label="$t('PROJECT_FROM_LABEL') | capitalize"
                label-for="from-input"
                :state="state('from')">
              <b-form-datepicker
                  v-model="$v.form.from.$model"
                  locale="es-MX"
                  :state="state('from')"
                  button-variant="dark"></b-form-datepicker>

              <b-form-invalid-feedback id="from-input-feedback">
                <div v-for="error in errors('from')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_FROM_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- from input -->

            <!-- to input -->
            <b-form-group
                id="to-form-group"
                class="d-inline-block w-50 pl-1"
                :label="$t('PROJECT_TO_LABEL') | capitalize"
                label-for="to-input"
                :state="state('to')">
              <b-form-datepicker
                  v-model="$v.form.to.$model"
                  locale="es-MX"
                  :state="state('to')"
                  button-variant="dark"></b-form-datepicker>

              <b-form-invalid-feedback id="to-input-feedback">
                <div v-for="error in errors('to')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_TO_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- to input -->
          </div>


          <!-- organization -->
          <b-form-group
              :label="$t('PROJECT_ORGANIZATION_LABEL') | capitalize"
              label-for="organization-input"
              :state="state('organization')">
            <organization-single-selector
                id="organization-input"
                :state="state('organization')"
                :errors="errors('organization')"
                v-model="$v.form.organization.$model">
            </organization-single-selector>
          </b-form-group>
          <!-- organization -->

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>

    <!-- invited organizations -->
    <organization-selector :title="$t('PROJECTS_INVITED_ORGANIZATIONS_LABEL') | capitalize"
                           :loading="loadingProjectOrganizations" :initial-values="organizations"
                           v-model="form.organizations" class="my-1"></organization-selector>
    <!-- invited organizations -->

    <!-- donors -->
    <company-selector :title="$t('DONOR_COMPANIES_LABEL') | capitalize" :loading="loadingProjectCompanies"
                      :initial-values="companies" v-model="form.companies" class="my-1"
                      :only-donors="true"></company-selector>
    <!-- donors -->

    <!-- users -->
    <user-selector filter-by="organizations" :owner-organization="form.organization" :organizations="form.organizations"
                   :title="$t('PROJECT_USERS_LABEL') | capitalize" :loading="loadingProjectUsers"
                   :initial-values="users" v-model="form.users" class="my-1" :only-donors="true"></user-selector>
    <!-- users -->

    <!-- budget details -->
    <b-card bg-variant="dark" text-variant="light" :title="$t('PROJECT_BUDGET_DETAILS') | capitalize" class="mb-2">
      <b-card-text>
        <b-button @click="addDetail">{{ $t('FORMS_DETAIL_ADD') | capitalize }}</b-button>
        <table class="w-100">
          <thead>
          <tr>
            <th>{{ $t('PROJECT_BUDGET_DESCRIPTION_LABEL') | capitalize }}</th>
            <th>{{ $t('PROJECT_BUDGET_AMOUNT_LABEL') | capitalize }}</th>
            <th>{{ $t('PROJECT_BUDGET_CURRENCY_LABEL') | capitalize }}</th>
            <th>{{ $t('PROJECT_BUDGET_ACCOUNT_LABEL') | capitalize }}</th>
            <th>{{ $t('PROJECT_BUDGET_BUSINESS_SEGMENT_LABEL') | capitalize }}</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="index" v-for="(v, index) in $v.form.budgetDetails.$each.$iter">
            <td class="budget-description">
              <b-form-group
                  id="bank-account-code-form-group"
                  label-for="project-budget-description-input"
                  :state="state2(v, 'expenditureType')">
                <expenditure-type-single-selector class="w-100" v-model="v.expenditureType.$model"
                                                  :state="state2(v, 'expenditureType')"></expenditure-type-single-selector>

                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'expenditureType')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_DETAIL_DESCRIPTION_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>
            <td class="budget-amount">
              <b-form-group
                  id="bank-account-code-form-group"
                  label-for="project-budget-amount-input"
                  :state="state2(v, 'amount')">
                <b-form-input id="type-input" type="text"
                              v-model="v.amount.$model"
                              :state="state2(v, 'amount')"
                              trim></b-form-input>
                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'amount')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_DETAIL_AMOUNT_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>
            <td>
              <b-form-group
                  id="currency-input-form-group"
                  label-for="project-budget-currency-input"
                  :state="state2(v, 'currency')">
                <b-select id="currency-input"
                          v-model="v.currency.$model"
                          :state="state2(v, 'currency')">
                  <b-select-option :value="'MXN'">{{ $t('PROJECT_DETAIL_CURRENCY_MXN') | capitalize }}</b-select-option>
                  <b-select-option :value="'USD'">{{ $t('PROJECT_DETAIL_CURRENCY_USD') | capitalize }}</b-select-option>
                </b-select>
                <b-form-invalid-feedback id="currency-input-feedback">
                  <div v-for="error in errors2(v, 'currency')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_BUDGET_CURRENCY_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>
            <td >
              <b-form-group
                  label-for="project-budget-account-input"
                  :state="state2(v, 'account')">
                <b-form-input id="type-input" type="text"
                              v-model="v.account.$model"
                              :state="state2(v, 'account')"
                              trim></b-form-input>
                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'account')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_BUDGET_ACCOUNT_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>

            <td>
              <b-form-group
                  label-for="project-budget-account-input"
                  :state="state2(v, 'segmentName')">
                <b-form-input id="type-input" type="text"
                              v-model="v.segmentName.$model"
                              :state="state2(v, 'segmentName')"
                              trim></b-form-input>
                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'segmentName')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROJECT_BUDGET_BUSINESS_SEGMENT_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>

            <td style="vertical-align: top">
              <b-button @click="removeAccount(index)" variant="danger">
                <b-icon-x></b-icon-x>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card-text>
    </b-card>
    <!-- budget details -->
  </div>
</template>

<script>
import {required, maxLength, minValue, decimal} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Project from "@/project";
import Constants from "@/constants";
import OrganizationSingleSelector from "@/organization/OrganizationSingleSelector";
import OrganizationSelector from "@/organization/OrganizationSelector";
import CompanySelector from "@/company/CompanySelector";
import UserSelector from "@/users/UserSelector";
import ExpenditureTypeSingleSelector from "@/expenditureType/ExpenditureTypeSingleSelector";

export default {
  name: "ProjectForm",
  components: {
    ExpenditureTypeSingleSelector,
    CompanySelector,
    UserSelector,
    OrganizationSingleSelector,
    OrganizationSelector
  },
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    addDetail() {
      this.form.budgetDetails.push({expenditureType: null, amount: null, currency: 'MXN', account: null, segmentName: null})
    },
    removeAccount(idx) {
      this.form.budgetDetails.splice(idx, 1);
    },
    async doSave(id, data) {
      return id ? await Project.update(id, data) : await Project.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Project.findById(this.id)
          .then(resp => {
            console.log(resp.data);
            this.form.title = resp.data.title;
            this.form.from = resp.data.from;
            this.form.to = resp.data.to;
            this.form.organization = resp.data._embedded.organization._links.self.href.replace('{?projection}', '');
            this.form.budgetDetails = resp.data.budgetDetails.map(x => {
              return {
                amount: x.amount,
                currency: x.currency,
                expenditureType: x._embedded.expenditureType._links.self.href.replace('{?projection}', ''),
                spent: x.spent,
                account: x.account,
                segmentName: x.segmentName,
                balance: x.balance};
            });
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });

      this.loadingProjectOrganizations = true;
      Project.findProjectOrganizations(this.id)
          .then(resp => {
            this.organizations = resp.data._embedded.organizations;
            this.form.organizations = this.organizations.map(x => x._links.self.href);
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingProjectOrganizations = false;
          });

      this.loadingProjectCompanies = true;
      Project.findProjectCompanies(this.id)
          .then(resp => {
            this.companies = resp.data._embedded.companies;
            this.form.companies = this.companies.map(x => x._links.self.href);
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingProjectCompanies = false;
          });

      this.loadingProjectUsers = true;
      Project.findProjectUsers(this.id)
          .then(resp => {
            console.log(resp.data);
            this.users = resp.data._embedded.users;
            this.form.users = this.users.map(x => x._links.self.href);
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingProjectUsers = false;
          });
    }
  },
  data() {
    return {
      organizations: [],
      companies: [],
      users: [],
      loadingFormData: false,
      loadingProjectOrganizations: false,
      loadingProjectCompanies: false,
      loadingProjectUsers: false,
      originals: {},
      form: {
        title: null,
        from: null,
        to: null,
        organization: null,
        organizations: [],
        companies: [],
        users: null,
        budgetDetails: [],
      },
      editPage: 'PROJECT_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      title: {required},
      from: {required},
      to: {required, minValue: (v1, v2) => v1 >= v2.from},
      organization: {},
      organizations: {},
      companies: {required},
      users: {required},
      budgetDetails: {
        required, minLength: 1, $each: {
          expenditureType: {required},
          amount: {required, minValue: minValue(0), decimal},
          currency: {required, maxLength: maxLength(4)},
          account: {required, maxLength: maxLength(60)},
          segmentName: {required, maxLength: maxLength(60)},
        }
      },
    }
  }
}
</script>

<style scoped>
.budget-description {
  width: 20%;
}

.budget-amount {
  width: 10%;
}
</style>
